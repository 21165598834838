import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { AuthContext } from '../../contexts/Aut.context';

import LoadingSpinner from '../loading-spinner/LoadingSpinner';

const AnonRoute = () => {
  const { isLoggedIn, isLoading, user } = React.useContext(AuthContext)

  if (isLoading) return <LoadingSpinner />

  if (isLoggedIn)
  // TODO: fix this shaize
    return user ? (
      <Navigate to="/dashboard" />
    ) : (
      <Navigate to="/" />
    )

  return <Outlet />
}

export default AnonRoute