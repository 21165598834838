import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import NavBar from './components/NavBar';
import EditUser from './components/user/EditUser';
import UserDetails from './components/user/UserDetails';
import { CreateUser } from './components/user/CreateUser';

import HomePage from './pages/home-page/HomePage';
import LoginPage from './pages/login-page/LogInPage';
import SignUpPage from './pages/sign-up-page/SignUpPage';
import NotFoundPage from './pages/errors/not-found-page/NotFoundPage';
import Dashboard from './pages/dashboard/Dashboard';

import AnonRoute from './components/anon-route/AnonRoute';
import PrivateAdminRoute from './components/private-admin-route/PrivateAdminRoute';

function App() {
  return (
    <React.Fragment>
      <NavBar />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />

          {/* Anon routes */}
          {/* <Route path="/dashboard" element={<AnonRoute />}>
            <Route path="" element={<LoginPage />} />
          </Route> */}
          <Route path="/login" element={<AnonRoute />}>
            <Route path="" element={<LoginPage />} />
          </Route>
          <Route path="/sign-up" element={<AnonRoute />}>
            <Route path="" element={<SignUpPage />} />
          </Route>

          {/* Private routes admin */}
          <Route path="/dashboard/" element={<PrivateAdminRoute />}>
            <Route path="" element={<Dashboard />} />
            <Route path="user/edit/:id" element={<EditUser />} />
            <Route path="user/view/:id" element={<UserDetails />} />
            <Route path="user/create" element={<CreateUser />} />
          </Route>

          {/* Error routes */}
          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </main>
    </React.Fragment>
  );
}

export default App;
