import * as React from 'react';
import axiosInstance from '../services/http';

const AuthContext = React.createContext();

const AuthProviderWrapper = (props) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);

  const verifyStoredToken = async () => {
    const storedToken = localStorage.getItem('authToken');

    if (storedToken) {
      try {
        // const isValidToken = await axiosInstance.get(`/users/verify`, {
        //   headers: { Authorization: `Bearer ${storedToken}` },
        // })

        const userId = localStorage.getItem('uid');
        const { data: user } = await axiosInstance.get(`/users/${userId}`, {
          headers: { Authorization: `Bearer ${storedToken}` },
        });

        setUser(user);
        setIsLoggedIn(true);
        setIsLoading(false);
      } catch (err) {
        setIsLoggedIn(false);
        setUser(null);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const logInUser = (token, userId) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('uid', userId);
    verifyStoredToken();
  };

  const logOutUser = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('uid');
    setIsLoggedIn(false);
    setUser(null);
  };

  React.useEffect(() => {
    verifyStoredToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        user,
        logInUser,
        logOutUser,
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthProviderWrapper, AuthContext };
