import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import axiosInstance from '../../services/http';

import { Field, Form } from 'react-final-form';

import FormCustom from '../../components/form-custom/FormCustom';
import RFTextField from '../../components/form-custom/RFTextField';
import FormButton from '../../components/form-custom/FormButton';

function EditUser() {
  const [updatedUser, setUpdatedUser] = React.useState({});

  const { id } = useParams();

  const getData = async () => {
    try {
      const response = await axiosInstance.get(`/users/${id}`);
      setUpdatedUser(response.data)
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmit = async (formData) => {
    try {
      const body = {
        username: formData.username,
        name: formData.name,
        email: formData.email,
      };
      const response = await axiosInstance.put(`/users/${id}`, body);
      console.log(response);
    } catch (err) {
      console.log(err.message);
    }
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <FormCustom>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Edit User
          </Typography>
        </React.Fragment>
        <Form
          initialValues={updatedUser}
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
        >
          {({ handleSubmit, submitting }) => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 6 }}
            >
              <Field
                autoComplete="user-name"
                label="Name"
                component={RFTextField}
                disabled={submitting}
                fullWidth
                name="name"
                required
              />
              <Field
                autoComplete="username"
                label="Username"
                component={RFTextField}
                disabled={submitting}
                fullWidth
                margin="normal"
                name="username"
                required
              />
              <Field
                autoComplete="email"
                label="Email"
                component={RFTextField}
                disabled={submitting}
                fullWidth
                margin="normal"
                name="email"
                required
              />
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting}
                color="secondary"
                fullWidth
              >
                {submitting ? 'In progress…' : 'Save Changes'}
              </FormButton>
            </Box>
          )}
        </Form>
      </FormCustom>
    </React.Fragment>
  );
}

export default EditUser;
