import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AuthContext } from '../../contexts/Aut.context';

import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import Forbidden from '../../pages/errors/forbidden/Forbidden';

const PrivateAdminRoute = () => {
  const { isLoggedIn, isLoading, user } = React.useContext(AuthContext)

  if (isLoading) return <LoadingSpinner />
  if (!isLoggedIn) return <Navigate to="/login" />
  // TODO: fix this shit
  //   if (!user.isAdmin) return <Forbidden />
  if (!user) return <Forbidden />

  return (
    <>
      <Outlet />
    </>
  )
}

export default PrivateAdminRoute