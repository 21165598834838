import * as React from 'react';
import axiosInstance from '../../services/http';

import { useNavigate } from "react-router-dom";
import { Field, Form, FormSpy } from 'react-final-form';
import { Link, Box, Typography } from '@mui/material';

import { email, required } from '../../utils/validation';

import FormCustom from '../../components/form-custom/FormCustom';
import RFTextField from '../../components/form-custom/RFTextField';
import FormButton from '../../components/form-custom/FormButton';
import FormFeedback from '../../components/form-custom/FormFeedback';

import { AuthContext } from '../../contexts/Aut.context';

export default function LogIn() {
  const [submitError, setSubmitError] = React.useState('');
  const { logInUser } = React.useContext(AuthContext)
  const navigate = useNavigate()

  const validate = (values) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = async (body) => {
    try {
      const { data: { token, userId } } = await axiosInstance.post('/users/check-credentials', body);
      setSubmitError('');
      logInUser(token, userId);
      navigate('/dashboard')
    } catch (error) {
      console.log('Error', error.message);
      setSubmitError('Invalid email or password.');
    }
  };

  return (
    <React.Fragment>
      <FormCustom>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center" color='white' backgroundColor="primary">
            Login
          </Typography>
          <Typography variant="body2" align="center" color='white'>
            {'Not a member yet? '}
            <Link
              href="/sign-up/"
              align="center"
              underline="always"
            >
              Sign Up here
            </Link>
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit, submitting }) => (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 6 }}>
              <Field
                autoComplete="email"
                autoFocus
                component={RFTextField}
                disabled={submitting}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                size="large"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting}
                required
                name="password"
                autoComplete="current-password"
                label="Password"
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              {submitError && (
                <FormFeedback error sx={{ mt: 2 }}>
                  {submitError}
                </FormFeedback>
              )}
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting}
                size="large"
                color="primary"
                fullWidth
              >
                {submitting ? 'In progress…' : 'Sign In'}
              </FormButton>
            </Box>
          )}
        </Form>
        <Typography align="center">
          <Link underline="always" href="/">
            Forgot password?
          </Link>
        </Typography>
      </FormCustom>
    </React.Fragment>
  );
}