import * as React from "react";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../services/http";

import { Field, Form, FormSpy } from "react-final-form";
import { Grid, Link, Box, Typography } from "@mui/material";

import { email, password, required } from "../../utils/validation";

import FormCustom from "../../components/form-custom/FormCustom";
import RFTextField from "../../components/form-custom/RFTextField";
import FormButton from "../../components/form-custom/FormButton";
import FormFeedback from "../../components/form-custom/FormFeedback";

export default function SignUpPage() {
  const navigate = useNavigate();

  const validate = (values) => {
    const errors = required(
      ["username", "name", "email", "password", "passwordRepeat"],
      values
    );
    if (!errors.email && !errors.password) {
      const emailError = email(values.email);
      const passwordError = password(values.password, values.passwordRepeat);
      if (emailError) {
        errors.email = emailError;
      }
      if (passwordError) {
        errors.password = passwordError;
      }
    }

    return errors;
  };

  const handleSubmit = async (formData) => {
    try {
      const body = {
        username: formData.username,
        name: formData.name,
        email: formData.email,
        password: formData.password,
      };
      await axiosInstance.post("/users", body);
      // we do not automatically login after register, manual login to avoid security issues
      navigate("/login");
    } catch (err) {
      console.log("no way dude");
    }
  };

  return (
    <React.Fragment>
      <FormCustom>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign Up
          </Typography>
          <Typography variant="body2" align="center">
            <Link href="/login/" underline="always">
              Already have an account?
            </Link>
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
        >
          {({ handleSubmit, submitting }) => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 6 }}
            >
              <Field
                autoComplete="user-name"
                label="Username"
                autoFocus
                component={RFTextField}
                disabled={submitting}
                fullWidth
                name="username"
                required
              />
              <Field
                autoComplete="name"
                label="Full name"
                component={RFTextField}
                disabled={submitting}
                fullWidth
                margin="normal"
                name="name"
                required
              />
              <Field
                autoComplete="email"
                label="Email"
                component={RFTextField}
                disabled={submitting}
                fullWidth
                margin="normal"
                name="email"
                required
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    autoComplete="new-password"
                    label="Password"
                    fullWidth
                    component={RFTextField}
                    disabled={submitting}
                    required
                    name="password"
                    type="password"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    autoComplete="repeat-password"
                    label="Repeat password"
                    fullWidth
                    component={RFTextField}
                    disabled={submitting}
                    required
                    name="passwordRepeat"
                    type="password"
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback error sx={{ mt: 2 }}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={submitting}
                color="secondary"
                fullWidth
              >
                {submitting ? "In progress…" : "Sign Up"}
              </FormButton>
            </Box>
          )}
        </Form>
      </FormCustom>
    </React.Fragment>
  );
}
