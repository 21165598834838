import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Box, Container, Typography } from "@mui/material";

const Forbidden = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h1" color="text.secondary">
          Forbidden
        </Typography>
        <Typography variant="h4" color="text.secondary">
          You do not have permission to access this resource
        </Typography>
        <Button
          component={RouterLink}
          to="/"
          variant="outlined"
          color="inherit"
          sx={{ mt: 3 }}
        >
          Back to Homepage
        </Button>
      </Box>
    </Container>
  );
};

export default Forbidden;
