import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(4),
  },
}));

function NotFoundPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">Page not found</Typography>
    </div>
  );
}

export default NotFoundPage;
