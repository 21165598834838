import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  AddBox,
  Edit,
  Delete,
  Visibility,
  AccountCircle,
  Settings,
  Notifications,
  Description,
  Group,
} from '@material-ui/icons';

import axiosInstance from '../../services/http';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = React.useState('users');

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [users, setUsers] = React.useState([]);

  const handleCreate = () => {
    navigate(`/dashboard/user/create/`);
  };

  const handleEdit = (user) => {
    navigate(`/dashboard/user/edit/${user.userId}`);
  };

  const handleDelete = (user) => {
    console.log("Delete:", user);
  };

  const handleViewDetails = (user) => {
    navigate(`/dashboard/user/view/${user.userId}`);
  };

  const getUsers = async () => {
    try {
      const response = await axiosInstance.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.drawer}>
        <List>
          <ListItem
            button
            onClick={() => handleSectionClick('profile')}
            selected={activeSection === 'profile'}
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleSectionClick('settings')}
            selected={activeSection === 'settings'}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleSectionClick('notifications')}
            selected={activeSection === 'notifications'}
          >
            <ListItemIcon>
              <Notifications />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleSectionClick('documents')}
            selected={activeSection === 'documents'}
          >
            <ListItemIcon>
              <Description />
            </ListItemIcon>
            <ListItemText primary="Documents" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleSectionClick('users')}
            selected={activeSection === 'users'}
          >
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
      </div>
      <main className={classes.content}>
        {activeSection === 'profile' && (
          <div>
            <Typography variant="h3">Profile Section</Typography>
            <Typography paragraph>This is the profile section.</Typography>
          </div>
        )}
        {activeSection === 'settings' && (
          <div>
            <Typography variant="h3">Settings Section</Typography>
            <Typography paragraph>This is the settings section.</Typography>
          </div>
        )}
        {activeSection === 'notifications' && (
          <div>
            <Typography variant="h3">Notifications Section</Typography>
            <Typography paragraph>
              This is the notifications section.
            </Typography>
          </div>
        )}
        {activeSection === 'documents' && (
          <div>
            <Typography variant="h3">Documents Section</Typography>
            <Typography paragraph>This is the documents section.</Typography>
          </div>
        )}
        {activeSection === 'users' && (
          <React.Fragment>
            <Box display="flex" justifyContent="center" my={2}>
              <Typography variant="h4" component="h1">
                Users
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mb={2}>
              <IconButton onClick={() => handleCreate()}>
                <AddBox />
              </IconButton>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.userId}>
                      <TableCell>{user.userId}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEdit(user)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(user)}>
                          <Delete />
                        </IconButton>
                        <IconButton onClick={() => handleViewDetails(user)}>
                          <Visibility />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </main>
    </div>
  );
};

export default Dashboard;
