import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';

import axiosInstance from '../../services/http';

export default function UserDetails() {
  const [user, setUser] = React.useState({});
  const { id } = useParams();

  const getData = async () => {
    try {
      const response = await axiosInstance.get(`/users/${id}`, {
        headers: { Authorization: `Bearer ${''}` },
      });
      setUser(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xs">
      <Box mt={4} mb={2}>
        <Typography variant="h5" component="h2">
          User Details
        </Typography>
      </Box>
      {user ? (
        <Box>
          <Typography variant="subtitle1">Name: {user.name}</Typography>
          <Typography variant="subtitle1">Username: {user.username}</Typography>
          <Typography variant="subtitle1">Email: {user.email}</Typography>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Container>
  );
}
