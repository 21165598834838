import React, { useState } from 'react';
import axiosInstance from '../../services/http';

import { Typography } from '@mui/material';
import TextField from '../../components/TextField';
import HomePageLayout from './HomePageLayout';

const backgroundImage =
  'https://images.unsplash.com/photo-1655635643617-72e0b62b9278?auto=format&fit=crop&w=1632';

export default function HomePage() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/leads', { email });
      console.log('Respuesta:', response.data);
      setEmail('');
      setSubmitted(true);
    } catch (error) {
      console.log('Error', error.message)
    }

  };

  return (
    <HomePageLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9',
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />

      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Enter your email address to receive our exclusive news and offers.
      </Typography>

      {submitted ? (
        <Typography variant="body1" color="primary">Thank you for subscribing!</Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address."
            variant="outlined"
            fullWidth
          />
        </form>
      )}
    </HomePageLayout>
  );
}
